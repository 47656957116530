import validator from 'validator'

export default class Contato{
    constructor(form) {
        this.form = document.querySelector(form)
    }

    init() {
        this.checkValues()
    }

    checkValues() {
        this.form.addEventListener('submit', (e) => {
            e.preventDefault()
            const el = e.target 
            let erro = false
            const email = el.querySelector('input[name="email"]')
            const name = el.querySelector('input[name="nome"]')
            const phone = el.querySelector('input[name="telefone"]')
            for (const error of document.querySelectorAll('.error')) {
                error.remove()
            }
            if(name.value.length === 0) {
                this.createError('Nome é um campo obrigatório', name)
                erro = true
            }
            if(email.value.length === 0 && phone.value.length === 0) {
                this.createError('Pelo menos um contato precisa ser enviado: e-mail ou telefone ', email)
                this.createError('Pelo menos um contato precisa ser enviado: e-mail ou telefone ', phone)
                erro = true
            }
            if(email.value && !validator.isEmail(email.value)) {
                this.createError('E-mail Inválido', email)
                erro = true
            }
            if(!erro) el.submit()
        })
    }

    createError(string, parent) {
        const p = document.createElement('p')
        p.innerText = string
        p.classList = 'text-danger error'
        parent.before(p)
    }
}