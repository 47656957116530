import validator from 'validator'

export default class Login {
    constructor(form) {
        this.form = document.querySelector(form)
    }

    init() {
        this.checkValues()
    }

    checkValues() {
        this.form.addEventListener('submit', (e) => {
            e.preventDefault()
            const el = e.target 
            let erro = false
            const email = el.querySelector('input[name="email"]')
            const password = el.querySelector('input[name="password"]')
            for (const error of document.querySelectorAll('.error')) {
                error.remove()
            }
            if(!validator.isEmail(email.value)) {
                this.createError('E-mail Inválido', email)
                erro = true
            }
            if(password.value.length < 3 || password.value.length > 50) {
                this.createError('A senha precisa ter entre 3 e 50 caracteres', password)
                erro = true
            }
            if(!erro) el.submit()
        })
    }

    createError(string, parent) {
        const p = document.createElement('p')
        p.innerText = string
        p.classList = 'text-danger error'
        parent.before(p)
    }
}