import 'core-js/stable';
import 'regenerator-runtime/runtime';
// import './assets/css/style.css';
import Login from './modules/Login';
import Contato from './modules/Contato';

//Rota do login
const formLogin = document.querySelector('.form-login');
if (formLogin) {
    const loginRegister = new Login('.form-registrar');
    const loginEnter = new Login('.form-logar');
    loginRegister.init();
    loginEnter.init();
}


//Rota do contato
const formContato = document.querySelector('.form-contato');
const formEdit = document.querySelector('.form-edit');
if (formContato) {
    const ContatoRegister = new Contato('.form-contato-registrar');
    ContatoRegister.init();
}
if (formEdit) {
    const ContatoEdit = new Contato('.form-contato-editar');
    ContatoEdit.init()
}


